import { getProfileList } from "@/services/api/Master";
import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { ScrollArea } from "./ui/scroll-area";

const roles = [
  {
    uuid: "1",
    name: "I'm a Founder/Entrepreneur",
  },
  {
    uuid: "2",
    name: "I'm an Investor",
  },
  {
    uuid: "3",
    name: "i'm a Chief Investment Officer",
  },
  {
    uuid: "4",
    name: "I'm a Knowledge Seeker",
  },
];

function SelectRole({ theme, isModal, value, onChange, disabled = false }) {
  return (
    <Select disabled={disabled} value={value} onValueChange={onChange}>
      <SelectTrigger
        className={
          isModal
            ? "bg-white text-background border-background/20 focus:ring-0"
            : "bg-white/5 hover:bg-white/10"
        }
      >
        <SelectValue placeholder='-- Select --' />
      </SelectTrigger>
      <SelectContent>
        <ScrollArea className='h-64'>
          {roles.map((role) => (
            <SelectItem key={`select-role-${role.uuid}`} value={role.name}>
              {role.name}
            </SelectItem>
          ))}
        </ScrollArea>
      </SelectContent>
    </Select>
  );
}

SelectRole.defaultProps = {
  isModal: true,
};

export default SelectRole;
